import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from 'src/state/layout/layoutActions';
import ChevronIcon from 'src/assets/icons/ChevronIcon';
import { colors } from 'src/config/colors';
import { CleanButton } from 'src/common/components/Button';
import { MobileMenuState } from 'src/state/layout/layoutReducer';
import { media } from 'src/config/breakpoints';
import { layoutMobileMenuSelector } from 'src/state/layout/layoutSelectors';

const BackButton = styled(CleanButton)`
  color: ${colors.blue};
`;

const Container = styled.div<{ show: boolean }>`
  align-items: center;
  display: ${({ show }) => (show ? 'grid' : 'none')};
  grid-template-areas: 'back title .';
  grid-template-columns: 1fr auto 1fr;
  justify-items: start;
  width: 100%;

  ${media.w.min.px576(css`
    display: none;
  `)};
`;

const Title = styled.div`
  color: ${colors.blue};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  text-align: center;
`;

export const LayoutMobileMenuHeaderCategories: FC = () => {
  const dispatch = useDispatch();
  const mobileMenuState = useSelector(layoutMobileMenuSelector);

  return (
    <Container show={mobileMenuState === MobileMenuState.Categories}>
      <BackButton onClick={() => dispatch(layoutActions.showMobileMenu())}>
        <ChevronIcon direction="left" size={18} />
      </BackButton>

      <Title>Kategorie szkoleń</Title>
    </Container>
  );
};
