import { evolve } from 'ramda';
import { parseISO } from 'date-fns';
import { StoredImageDTO } from './image';

export interface ElearningLessonAgendaDTO {
  readonly id: number;
  item?: string;
}

export interface ElearningLessonDTO {
  readonly id: number;
  agenda: ElearningLessonAgendaDTO[];
  coverPhoto?: StoredImageDTO;
  duration: number;
  lastWatchedFragment?: number;
  progressPct: number;
  title?: string;
  videoId: number;
}

export interface ElearningLesson extends Omit<ElearningLessonDTO, 'agenda'> {
  agenda: ElearningLessonAgendaDTO[];
}

export interface ElearningLessonVideoDTO {
  eventHubSasToken: string;
  eventHubUrl: string;
  userToken: string;
  validUntil: string;
  videoUrl: string;
}

export interface ElearningLessonVideo extends Omit<ElearningLessonVideoDTO, 'validUntil'> {
  validUntil: Date;
}

export const mapToElearningLessonVideo: (dto: ElearningLessonVideoDTO) => ElearningLessonVideo =
  evolve({
    validUntil: parseISO,
  });
