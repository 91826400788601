import React, { memo } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { ResumeElearningButton } from 'src/course/components/ResumeElearningButton';
import { Button } from 'src/common/components/Button';
import MedalBadgeIcon from 'src/assets/icons/MedalBadgeIcon';
import { ElearningProgress, mapToCourseExcerpt } from 'src/common/models/elearningProgress';
import { Duration } from 'src/common/components/Duration';
import { generateSurveyLink } from 'src/common/components/CourseCard/config';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const Container = styled.div`
  position: relative;
  background: ${colors.grey08};
  border-radius: 0.25rem;
  margin-top: 0.625rem;
  padding: 0.6875rem 0 0.6875rem 2.3125rem;
`;

const CourseTitle = styled.div`
  font-weight: 700;
  padding-right: 2.8125rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const StyledButton = styled(Button)`
  margin-top: 0.5625rem;
`;

const StyledMedalBadgeIcon = styled(MedalBadgeIcon)`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
`;

const TimeInfoContainer = styled.div`
  font-weight: 300;
  line-height: 1.0625rem;
  margin-bottom: 0.5625rem;
`;

interface UserPanelElearningCalendarCardProps {
  elearningProgress: ElearningProgress;
}

export const UserPanelElearningCalendarCard = memo<UserPanelElearningCalendarCardProps>(
  ({ elearningProgress }) => {
    const courseExcerpt = mapToCourseExcerpt(elearningProgress);

    const duration = Math.ceil(elearningProgress.timeLeft / 60);

    return (
      <Container>
        <TimeInfoContainer>
          {elearningProgress.isCompleted && <>Ukończono!</>}

          {!elearningProgress.isCompleted && (
            <>
              {elearningProgress.progressPct}%
              {duration > 0 && (
                <>
                  &nbsp;&bull; Pozostało&nbsp;
                  <Duration
                    duration={Math.ceil(elearningProgress.timeLeft / 60)}
                    labels={{ h: 'h', m: 'min' }}
                    padMinutes={false}
                    showEmptyValues={false}
                  />
                </>
              )}
            </>
          )}
        </TimeInfoContainer>

        <CourseTitle>{elearningProgress.title}</CourseTitle>

        {!elearningProgress.isCompleted && <ResumeElearningButton courseExcerpt={courseExcerpt} />}

        {elearningProgress.isCompleted && (
          <StyledButton
            as={GatsbyLink}
            kind="primary"
            size="tiny"
            to={generateSurveyLink(courseExcerpt)}
          >
            Odbierz certyfikat
          </StyledButton>
        )}

        {elearningProgress.isCompleted && <StyledMedalBadgeIcon size={38} />}
      </Container>
    );
  },
);
UserPanelElearningCalendarCard.displayName = 'UserPanelElearningCalendarCard';
