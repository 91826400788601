import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const ChartIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8889 0C33.8526 0 35.4444 1.59188 35.4444 3.55556V60.4444C35.4444 62.4081 33.8526 64 31.8889 64C29.9252 64 28.3333 62.4081 28.3333 60.4444V3.55556C28.3333 1.59188 29.9252 0 31.8889 0ZM53.2222 21.3333C55.1859 21.3333 56.7778 22.9252 56.7778 24.8889V60.4444C56.7778 62.4081 55.1859 64 53.2222 64C51.2585 64 49.6667 62.4081 49.6667 60.4444V24.8889C49.6667 22.9252 51.2585 21.3333 53.2222 21.3333ZM10.5556 35.5556C12.5192 35.5556 14.1111 37.1474 14.1111 39.1111V60.4444C14.1111 62.4081 12.5192 64 10.5556 64C8.59188 64 7 62.4081 7 60.4444V39.1111C7 37.1474 8.59188 35.5556 10.5556 35.5556Z"
    />
  </IconBox>
);

export default ChartIcon;
