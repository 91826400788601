import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { getCourseCategories } from 'src/common/services/course';
import { useContext, useMemo } from 'react';
import { GlobalPagePropsContext } from 'src/common/services/globalPagePropsContext';
import { mapToCategory } from 'src/common/models/category';

export function useCourseCategories() {
  const { courseCategories } = useContext(GlobalPagePropsContext);
  return useQuery(QueryKey.CourseCategories, getCourseCategories, {
    cacheTime: Infinity,
    initialData: () => courseCategories?.map(mapToCategory),
  });
}

export function useCommonCourseCategories() {
  const { data, isLoading } = useCourseCategories();

  const result = useMemo(() => data?.filter(({ isSpecialCategory }) => !isSpecialCategory), [data]);

  return { data: result, isLoading };
}

export function usePartnerCourseCategories() {
  const { data, isLoading } = useCourseCategories();

  const result = useMemo(
    () => (data ?? []).filter(({ isSpecialCategory }) => isSpecialCategory).slice(0, 4), // explicitly limited by business requirement
    [data],
  );

  return { data: result, isLoading };
}
