import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { LayoutMenuLink } from 'src/common/components/Layout/LayoutMenuLink';
import { LayoutHeaderDesktopColumn } from 'src/common/components/Layout/LayoutColumn';
import { StyleProps } from 'src/lib/styleProps';
import { MenuItem } from 'src/common/components/Layout/config';
import { coerceLinkProps } from 'src/common/utils/coerceLinkProps';

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MenuLeft = styled.div`
  margin-left: -0.875rem;
`;

const MenuLeftLink = styled(LayoutMenuLink)`
  margin: 0 2rem;
`;

const MenuRight = styled.div`
  margin-left: auto;
  margin-right: -0.5rem;
`;

const MenuRightLink = styled(LayoutMenuLink)`
  color: ${colors.blue07};
  margin: 0 0.8125rem;
`;

export interface LayoutHeaderContentProps {
  leftMenuItems?: MenuItem[] | null;
  rightMenuItems?: MenuItem[] | null;
}

export const LayoutHeaderContent: FC<LayoutHeaderContentProps & StyleProps> = ({
  className,
  leftMenuItems,
  rightMenuItems,
  style,
}) => (
  <LayoutHeaderDesktopColumn className={className} style={style}>
    <HeaderContent>
      {leftMenuItems && (
        <MenuLeft>
          {leftMenuItems.map((item, index) => (
            <MenuLeftLink
              key={index}
              {...coerceLinkProps(item.url)}
              kind="link"
              dangerouslySetInnerHTML={{ __html: item.label }}
            />
          ))}
        </MenuLeft>
      )}

      {rightMenuItems && (
        <MenuRight>
          {rightMenuItems.map((item, index) => (
            <MenuRightLink
              key={index}
              kind="link"
              {...coerceLinkProps(item.url)}
              dangerouslySetInnerHTML={{ __html: item.label }}
            />
          ))}
        </MenuRight>
      )}
    </HeaderContent>
  </LayoutHeaderDesktopColumn>
);
