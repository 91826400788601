import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import { LayoutMobileMenuModal } from 'src/common/components/LayoutMobileMenu/LayoutMobileMenuModal';
import { layers } from 'src/config/layers';
import { layoutMobileMenuSelector } from 'src/state/layout/layoutSelectors';
import { useSelector } from 'react-redux';
import { LayoutMobileMenuHeaderDefault } from 'src/common/components/LayoutMobileMenu/LayoutMobileMenuHeaderDefault';
import { MobileMenuState } from 'src/state/layout/layoutReducer';
import { LayoutMobileMenuHeaderCategories } from 'src/common/components/LayoutMobileMenu/LayoutMobileMenuHeaderCategories';

const Container = styled.div`
  background: ${colors.white};
  box-shadow: ${shadows.drop1};
  display: flex;
  height: 3.375rem;
  padding: 0 1.125rem 0;
  position: relative;
  z-index: ${layers.mobileMenu};

  ${media.w.min.px768(css`
    height: 4rem;
  `)};

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

export const LayoutMobileMenu: FC = () => {
  const mobileMenuState = useSelector(layoutMobileMenuSelector);

  return (
    <Container>
      <LayoutMobileMenuHeaderDefault />
      <LayoutMobileMenuHeaderCategories />

      {mobileMenuState !== MobileMenuState.Hidden && <LayoutMobileMenuModal />}
    </Container>
  );
};
