import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { LayoutState } from 'src/state/layout/layoutReducer';

export const layoutStateSelector = (state: RootState) => state.layout;

export const layoutActivePanelCategorySelector = createSelector(
  layoutStateSelector,
  (state: LayoutState) => state.activePanelCategory,
);

export const layoutMobileMenuSelector = createSelector(
  layoutStateSelector,
  (state: LayoutState) => state.mobileMenu,
);
