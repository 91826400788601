import { widthBreakpoints } from 'src/config/breakpoints';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { getCourseSurveyPageRoute } from 'src/course/routes';
import { CourseType } from 'src/common/models/courseType';
import { getUserElearningSurveyPageRoute } from 'src/user/routes';

export interface CourseCardThemeParams {
  featured: boolean;
  featuredBreakpoint?: keyof typeof widthBreakpoints;
  largeMobileImage?: boolean;
  elearningProgressBar?: boolean;
}

export interface CourseCardStyledProps {
  theme: CourseCardThemeParams;
}

export const courseCardTypeLabelNameMapper = {
  [CourseType.StandardCourse]: 'Szkolenie online',
  [CourseType.ElearningCourse]: 'E-learning',
  [CourseType.CourseBundle]: 'Kurs',
};

export const generateDetailsLink = (courseExcerpt: CourseExcerpt) => courseExcerpt.link;

// todo: asserted that ID exists, Models require changes, and so do components
export const generateSurveyLink = (courseExcerpt: CourseExcerpt) =>
  courseExcerpt.courseType === CourseType.StandardCourse
    ? getCourseSurveyPageRoute(
        { id: courseExcerpt.id! },
        { id: courseExcerpt.courseId, slug: courseExcerpt.slug },
      )
    : getUserElearningSurveyPageRoute(courseExcerpt.courseId.toString());
