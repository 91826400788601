import React, { FC, ReactNode } from 'react';
import { Seo } from 'src/public/components/Seo';
import { Footer } from 'src/common/components/Footer';
import styled, { ThemeProvider } from 'styled-components';
import { UserPanel } from 'src/common/components/UserPanel';
import { LayoutHeaderContent } from 'src/common/components/Layout/LayoutHeaderContent';
import { LayoutHeaderRight } from 'src/common/components/Layout/LayoutHeaderRight';
import { LayoutColumn, LayoutDesktopColumn } from 'src/common/components/Layout/LayoutColumn';
import { LayoutMobileMenu } from 'src/common/components/LayoutMobileMenu';
import {
  layoutLeftMenuItems,
  layoutRightMenuItems,
  MenuItem,
} from 'src/common/components/Layout/config';
import { ScrollToTop } from 'src/common/components/ScrollToTop';
import { LayoutContainer } from 'src/common/components/Layout/LayoutContainer';
import { CookiePolicyBar } from 'src/common/components/CookiePolicyBar';
import { ClientOnly } from 'src/lib/SSR/ClientOnly/ClientOnly';
import { layers } from 'src/config/layers';

const ContentColumn = styled(LayoutColumn)<{ wide?: boolean }>`
  grid-area: ${({ wide }) => (wide ? 'content / content / panel-right / panel-right' : 'content')};
  z-index: ${layers.contentTop};
`;

const Wrapper = styled.div`
  position: relative;
`;

export interface LayoutProps {
  background?: string;
  leftMenuItems?: MenuItem[] | null;
  leftPanel?: ReactNode;
  rightMenuItems?: MenuItem[] | null;
  rightPanel?: ReactNode | null;
  scrollToTopButton?: boolean;
  seoDescription?: string;
  seoKeywords?: string;
  title?: string;
  wideContent?: boolean;
  widget?: ReactNode;
  widgetPadding?: number;
}

export const Layout: FC<LayoutProps> = ({
  background,
  children,
  leftMenuItems = layoutLeftMenuItems,
  leftPanel,
  rightMenuItems = layoutRightMenuItems,
  rightPanel = <UserPanel />,
  scrollToTopButton,
  seoDescription,
  seoKeywords,
  title,
  wideContent,
  widget,
  widgetPadding,
}) => (
  <ThemeProvider theme={{ layoutBackground: background }}>
    <Wrapper>
      <Seo description={seoDescription} keywords={seoKeywords} title={title} />

      <LayoutMobileMenu />

      <LayoutContainer>
        <LayoutHeaderContent
          leftMenuItems={leftMenuItems}
          rightMenuItems={rightMenuItems}
          style={{ gridArea: 'header-content' }}
        />
        <LayoutHeaderRight style={{ gridArea: 'header-right' }} />

        {leftPanel && (
          <LayoutDesktopColumn style={{ gridArea: 'panel-left' }}>{leftPanel}</LayoutDesktopColumn>
        )}

        <ContentColumn wide={wideContent}>{children}</ContentColumn>

        {!wideContent && (
          <LayoutDesktopColumn style={{ gridArea: 'panel-right' }}>
            {rightPanel}
          </LayoutDesktopColumn>
        )}
      </LayoutContainer>

      {widget}

      <ClientOnly>
        <CookiePolicyBar />
      </ClientOnly>
      <Footer widgetPadding={widgetPadding} />

      {scrollToTopButton && <ScrollToTop widgetPadding={widgetPadding} />}
    </Wrapper>
  </ThemeProvider>
);
