import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import { StyleProps } from 'src/lib/styleProps';
import { useUserQuery } from 'src/common/hooks/useUserQuery';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import Image from 'next/image';

const LoaderContainer = styled.div`
  border-radius: 6px;
  box-shadow: ${shadows.drop1};
  overflow: hidden;
`;

const CurrentUserInfoSkeleton: FC<IContentLoaderProps> = ({ className, style, ...props }) => (
  <div className={className} style={style}>
    <LoaderContainer>
      <ContentLoader
        height={77}
        speed={2}
        viewBox="0 0 170 77"
        backgroundColor={colors.darkGrey}
        foregroundColor={colors.grey}
        style={{ display: 'block', width: '100%' }}
        preserveAspectRatio="xMidYMid slice"
        uniqueKey="CurrentUserInfoSkeleton"
        {...props}
      >
        <rect x="0" y="0" rx="6" ry="6" width="170" height="77" />
      </ContentLoader>
    </LoaderContainer>
  </div>
);

const User = styled.div`
  align-items: center;
  display: flex;
`;

const UserAvatar = styled.div`
  flex: 0 0 4.8125rem;
  height: 4.8125rem;
  margin-right: 0.75rem;
  object-fit: contain;
  position: relative;
  width: 4.8125rem;
`;

const UserAvatarImage = styled(Image)`
  border-radius: 50%;
`;

const UserAvatarPlaceholder = styled(UserAvatar).attrs({ as: 'div' })`
  background-color: ${colors.blue05};
`;

const UserInfo = styled.div`
  flex: 1 1 auto;
  font-weight: 700;
`;

const UserName = styled.div`
  color: ${colors.blue};
`;

const UserText = styled.div`
  color: ${colors.pfp3};
`;

export const CurrentUserInfo: FC<StyleProps> = ({ className, style }) => {
  const { data: user, isLoading } = useUserQuery();

  if (isLoading) {
    return <CurrentUserInfoSkeleton className={className} style={style} />;
  }

  return (
    <User className={className} style={style}>
      {user?.avatar ? (
        <UserAvatar>
          <UserAvatarImage layout="fill" objectFit="contain" src={user?.avatar.url} />
        </UserAvatar>
      ) : (
        <UserAvatarPlaceholder />
      )}

      <UserInfo>
        <UserName>
          {user?.firstName}
          <br />
          {user?.lastName}
        </UserName>
        <UserText>i&nbsp;mój rozwój</UserText>
      </UserInfo>
    </User>
  );
};
