import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { halfGutter, tabletGutter } from 'src/config/layout';
import { media } from 'src/config/breakpoints';

export const LayoutContainer = styled.div`
  margin: 0 auto;
  max-width: 90rem;
  overflow: hidden;
  padding: 1.0625rem ${rem(halfGutter)} 2.25rem;

  ${media.w.min.px768(css`
    overflow: visible;
    padding: 2.5rem ${rem(tabletGutter)} 2.25rem;
  `)};

  ${media.w.min.px992(css`
    display: grid;
    grid-template-areas:
      '. header-content header-right'
      'panel-left content panel-right';
    /* 12 columns: 2.5 7 2.5 */
    grid-template-columns: 2.5fr minmax(0, 7fr) 2.5fr;
    padding: 0 ${rem(halfGutter)} ${({ theme }) => (theme.layoutBackground ? '0' : rem(36))};
  `)};

  ${media.w.min.px1200(css`
    grid-template-areas:
      '. . header-content header-right .'
      '. panel-left content panel-right .';
    /* 12 columns: 1 2 6 2 1 */
    grid-template-columns: 1fr 2fr minmax(0, 6fr) 2fr 1fr;
  `)};
`;
