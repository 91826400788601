import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const UserIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M11.9052 41.2393c2.5005-2.5004 5.8919-3.9051 9.4281-3.9051h21.3334c3.5362 0 6.9276 1.4047 9.4281 3.9051C54.5952 43.7396 56 47.1308 56 50.6668v5.3331c0 1.4727-1.1939 2.6665-2.6667 2.6665-1.4727 0-2.6666-1.1938-2.6666-2.6665v-5.3331c0-2.1216-.8429-4.1563-2.3432-5.6565-1.5003-1.5002-3.5351-2.343-5.6568-2.343H21.3333c-2.1217 0-4.1565.8428-5.6568 2.343-1.5003 1.5002-2.3432 3.5349-2.3432 5.6565v5.3331c0 1.4727-1.1939 2.6665-2.6666 2.6665C9.19391 58.6664 8 57.4726 8 55.9999v-5.3331c0-3.536 1.40476-6.9272 3.9052-9.4275z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32 10.669c-4.4183 0-8 3.5815-8 7.9996 0 4.418 3.5817 7.9995 8 7.9995s8-3.5815 8-7.9995c0-4.4181-3.5817-7.9996-8-7.9996zm-13.3333 7.9996c0-7.3634 5.9695-13.33266 13.3333-13.33266 7.3638 0 13.3333 5.96926 13.3333 13.33266S39.3638 32.0012 32 32.0012c-7.3638 0-13.3333-5.9692-13.3333-13.3326z"
    />
  </IconBox>
);

export default UserIcon;
