import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { WithClientOnly } from 'src/lib/SSR/ClientOnly/ClientOnly';

export const Portal = WithClientOnly(({ children }) => {
  const portalElement = useMemo(() => {
    const element = document.createElement('div');
    document.body.appendChild(element);
    return element;
  }, []);

  useEffect(
    () =>
      portalElement
        ? () => {
            document.body.removeChild(portalElement);
          }
        : undefined,
    [portalElement],
  );

  return ReactDOM.createPortal(children, portalElement);
});
