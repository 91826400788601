import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { CourseType } from 'src/common/models/courseType';
import { getElearningRoute } from 'src/elearning/routes';
import { StoredImageDTO } from './image';

export interface ElearningProgress {
  certificateDownloaded: boolean;
  coverPhoto: StoredImageDTO;
  currentLessonId: number;
  currentLessonLastWatchedFragment: number;
  duration: number;
  id: number;
  isCompleted: boolean;
  progressPct: number;
  slug?: string;
  timeLeft: number;
  title: string;
}

export const mapToCourseExcerpt = (elearningProgress: ElearningProgress): CourseExcerpt => {
  const elearningUserEnrollment = {
    farthestLessonId: elearningProgress.currentLessonId,
    isEnrolled: true,
    farthestPointInSeconds: elearningProgress.currentLessonLastWatchedFragment,
  };

  return {
    id: elearningProgress.id,
    title: elearningProgress.title,
    slug: elearningProgress.slug,
    courseId: elearningProgress.id,
    image: elearningProgress.coverPhoto,
    duration: elearningProgress.duration,
    courseType: CourseType.ElearningCourse,
    link: getElearningRoute(elearningUserEnrollment, {
      id: elearningProgress.id,
      slug: elearningProgress.slug,
    }),

    elearningUserEnrollment,

    progressStatus: {
      isCompleted: elearningProgress.isCompleted,
      certificateDownloaded: elearningProgress.certificateDownloaded,
      percentageWatched: elearningProgress.progressPct,
      viewingTimeLeft: elearningProgress.timeLeft,
    },
  };
};
