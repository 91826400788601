import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { StyleProps } from 'src/lib/styleProps';

const Container = styled.span`
  white-space: nowrap;
`;

export interface DurationFormatterProps {
  duration: number;
  labels?: { h: ReactNode; m: ReactNode };
  padMinutes?: boolean;
  showEmptyValues?: boolean;
}

export const Duration: FC<DurationFormatterProps & StyleProps> = ({
  className,
  duration,
  labels = { h: <>&nbsp;godz</>, m: <>&nbsp;min</> },
  padMinutes = true,
  showEmptyValues = true,
  style,
}) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  if (hours === 0 && minutes === 0) {
    return null;
  }

  return (
    <Container className={className} style={style}>
      {(showEmptyValues || hours > 0) && (
        <>
          <span className="duration-value duration-value-hours">{hours}</span>
          {labels.h}&nbsp;
        </>
      )}

      {(showEmptyValues || minutes > 0) && (
        <>
          <span className="duration-value duration-value-minutes">
            {padMinutes ? minutes.toString().padStart(2, '0') : minutes}
          </span>
          {labels.m}
        </>
      )}
    </Container>
  );
};
