import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const LogoutIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M10.3226 8.25806a2.06454 2.06454 0 00-2.06454 2.06454v43.3548c0 .5476.21752 1.0727.60469 1.4598a2.06442 2.06442 0 001.45985.6047h12.3871c2.2804 0 4.129 1.8487 4.129 4.1291S24.9901 64 22.7097 64H10.3226c-2.73774 0-5.36333-1.0876-7.29919-3.0234C1.08755 59.0407 0 56.4151 0 53.6774V10.3226c0-2.73774 1.08755-5.36333 3.02341-7.29919C4.95927 1.08755 7.58486 0 10.3226 0h12.3871c2.2804 0 4.129 1.84863 4.129 4.12903s-1.8486 4.12903-4.129 4.12903H10.3226z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M41.4674 13.5965c1.6125-1.6125 4.2269-1.6125 5.8394 0l15.4838 15.4838c1.6125 1.6125 1.6125 4.2269 0 5.8394L47.3068 50.4035c-1.6125 1.6125-4.2269 1.6125-5.8394 0-1.6125-1.6125-1.6125-4.2268 0-5.8393L54.0316 32 41.4674 19.4358c-1.6125-1.6125-1.6125-4.2269 0-5.8393z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M18.5806 32c0-2.2804 1.8487-4.129 4.1291-4.129H59.871C62.1514 27.871 64 29.7196 64 32c0 2.2804-1.8486 4.129-4.129 4.129H22.7097c-2.2804 0-4.1291-1.8486-4.1291-4.129z"
    />
  </IconBox>
);

export default LogoutIcon;
