import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { currentUserCalendarItemsRefetchInterval } from 'src/config/globals';
import { getCurrentUserElearnings } from 'src/common/services/elearning';

export const useCurrentUserElearningsQuery = () => {
  const { key, isAuthenticated } = useCurrentUserKeyEnhancer([QueryKey.CurrentUserElearnings]);

  return useQuery(key, () => getCurrentUserElearnings(1, 1000), {
    enabled: isAuthenticated,
    refetchInterval: currentUserCalendarItemsRefetchInterval,
  });
};
