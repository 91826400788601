import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const PlusOIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32 5.81818C17.5402 5.81818 5.81818 17.5402 5.81818 32 5.81818 46.4598 17.5402 58.1818 32 58.1818c14.4598 0 26.1818-11.722 26.1818-26.1818S46.4598 5.81818 32 5.81818zM0 32C0 14.3269 14.3269 0 32 0c17.6731 0 32 14.3269 32 32 0 17.6731-14.3269 32-32 32C14.3269 64 0 49.6731 0 32z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32 17.4545c1.6066 0 2.9091 1.3025 2.9091 2.9091v23.2728c0 1.6066-1.3025 2.9091-2.9091 2.9091s-2.9091-1.3025-2.9091-2.9091V20.3636c0-1.6066 1.3025-2.9091 2.9091-2.9091z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M17.4545 32c0-1.6066 1.3025-2.9091 2.9091-2.9091h23.2728c1.6066 0 2.9091 1.3025 2.9091 2.9091s-1.3025 2.9091-2.9091 2.9091H20.3636c-1.6066 0-2.9091-1.3025-2.9091-2.9091z"
    />
  </IconBox>
);

export default PlusOIcon;
