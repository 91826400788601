import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { currentUserCalendarItemsRefetchInterval } from 'src/config/globals';
import { getCurrentUserCalendarEvents } from 'src/common/services/event';

export const useCurrentUserCalendarEventsQuery = () => {
  const { key, isAuthenticated } = useCurrentUserKeyEnhancer([QueryKey.CurrentUserCalendarEvents]);

  return useQuery(key, () => getCurrentUserCalendarEvents(1, 1000), {
    enabled: isAuthenticated,
    refetchInterval: currentUserCalendarItemsRefetchInterval,
  });
};
