import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { halfGutter } from 'src/config/layout';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { layers } from 'src/config/layers';

export const LayoutColumn = styled.div`
  padding: 0 ${rem(halfGutter)};
  z-index: ${layers.layoutContent};

  ${({ theme }) =>
    theme.layoutBackground &&
    media.w.min.px992(css`
      background: ${theme.layoutBackground};
    `)}
`;

export const LayoutHeaderColumn = styled(LayoutColumn)`
  ${({ theme }) =>
    theme.layoutBackground &&
    media.w.min.px992(css`
      background: ${colors.white};
    `)}
`;

export const LayoutDesktopColumn = styled(LayoutColumn)`
  display: flex;

  ${media.w.max.px992(css`
    display: none;
  `)};
`;

export const LayoutHeaderDesktopColumn = styled(LayoutHeaderColumn)`
  align-items: center;
  display: flex;
  height: 3.8125rem;

  ${media.w.max.px992(css`
    display: none;
  `)};
`;
