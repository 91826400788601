import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { CalculatedImageDimensions, StoredImageDTO } from 'src/common/models/image';
import Image from 'next/image';

const LogoWrapper = styled.div<CalculatedImageDimensions>`
  height: ${({ $imageHeight }) => `${$imageHeight}rem`};
  width: ${({ $imageWidth }) => `${$imageWidth}rem`};
  max-width: 100%;
`;

export interface PartnerLogoImageProps {
  image: StoredImageDTO;
}

export const PartnerLogoImage: FC<PartnerLogoImageProps> = memo(({ image }) => {
  const calculatedHeight = Math.abs(1 - image.width / image.height) > 0.1 ? 2.1875 : 3.75;
  const calculatedWidth = Math.round((image.width / image.height) * calculatedHeight);

  return (
    <LogoWrapper $imageHeight={calculatedHeight} $imageWidth={calculatedWidth}>
      <Image src={image.url} width={image.width} height={image.height} objectFit="contain" />
    </LogoWrapper>
  );
});

PartnerLogoImage.displayName = 'PartnerLogoImage';
