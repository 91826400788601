import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const MedalBadgeIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="M19.8343 56.9378L28.7525 35.408L21.5908 32.4414L11.3158 57.2468C10.8829 58.2919 11.8622 59.3689 12.9436 59.0372L18.7699 57.2492C18.9427 57.1962 19.1091 57.1801 19.2804 57.1967L19.8343 56.9378Z"
        fill="#FFE07D"
      />
      <path
        d="M28.3682 35.25L19.2773 57.1967C19.7173 57.236 20.1233 57.4913 20.3407 57.9012L23.1961 63.2853C23.7261 64.2847 25.1802 64.2156 25.6132 63.1706L35.8883 38.3651L28.3682 35.25Z"
        fill="#FFD064"
      />
      <path
        d="M36.1537 35.1641L28.4336 38.362L38.7086 63.1676C39.1415 64.2127 40.5956 64.2817 41.1257 63.2823L43.9812 57.8981C44.069 57.7324 44.1879 57.5917 44.3273 57.4803L45.0264 56.5967L36.1537 35.1641Z"
        fill="#FFE07D"
      />
      <path
        d="M53.011 57.2469L42.7359 32.4414L35.2422 35.5455L44.3298 57.484C44.6666 57.2149 45.1232 57.1162 45.5569 57.2492L51.3831 59.0371C52.4646 59.3691 53.444 58.292 53.011 57.2469Z"
        fill="#FFD064"
      />
      <path
        d="M34.7419 0.899233C35.8165 1.75184 37.243 2.01846 38.5531 1.61172C40.3953 1.03961 42.3888 1.81197 43.3648 3.47581C44.0589 4.65904 45.2926 5.42302 46.6612 5.5169C48.5857 5.64889 50.1655 7.08924 50.4746 8.99333C50.6944 10.3474 51.569 11.5054 52.8112 12.0874C54.558 12.9058 55.5109 14.8195 55.1113 16.7066C54.827 18.0485 55.2242 19.4444 56.1723 20.4357C57.5055 21.8298 57.7027 23.9585 56.6484 25.5739C55.8985 26.7226 55.7646 28.1676 56.2906 29.4346C57.0302 31.2162 56.4451 33.2724 54.8785 34.3977C53.7644 35.198 53.1176 36.4971 53.1503 37.8684C53.1964 39.7969 51.9081 41.5028 50.0406 41.9863C48.7127 42.3301 47.6402 43.3078 47.1753 44.5984C46.5217 46.4132 44.704 47.5387 42.788 47.3149C41.4255 47.1557 40.0723 47.6798 39.1726 48.7155C37.9075 50.1717 35.806 50.5644 34.1003 49.6637C32.8873 49.0231 31.4361 49.0231 30.2231 49.6637C28.5174 50.5645 26.4159 50.1717 25.1508 48.7155C24.2511 47.6798 22.898 47.1557 21.5354 47.3149C19.6194 47.5387 17.8019 46.4132 17.1481 44.5984C16.6833 43.3078 15.6109 42.3301 14.2828 41.9863C12.4153 41.503 11.127 39.7969 11.1731 37.8684C11.2059 36.4971 10.559 35.198 9.44489 34.3977C7.87817 33.2724 7.29318 31.2162 8.03279 29.4346C8.55878 28.1676 8.42491 26.7226 7.67505 25.5739C6.62069 23.9585 6.81794 21.8298 8.15116 20.4357C9.09927 19.4444 9.49639 18.0485 9.21227 16.7066C8.81265 14.8195 9.76551 12.9056 11.5124 12.0874C12.7546 11.5054 13.6291 10.3474 13.8489 8.99333C14.1579 7.08924 15.7379 5.64902 17.6624 5.5169C19.031 5.42302 20.2647 4.65904 20.9588 3.47581C21.9348 1.81197 23.9284 1.03973 25.7705 1.61172C27.0806 2.01859 28.507 1.75184 29.5816 0.899233C31.0929 -0.299744 33.2308 -0.299744 34.7419 0.899233Z"
        fill="#E8AE4D"
      />
      <path
        d="M43.4867 25.0772C43.4867 16.6142 38.0271 9.42683 30.438 6.84375C21.1318 7.71223 13.8477 15.544 13.8477 25.0772C13.8477 34.6104 21.1318 42.4421 30.438 43.3106C38.0272 40.7275 43.4867 33.5402 43.4867 25.0772Z"
        fill="#FFE07D"
      />
      <path
        d="M32.1607 6.75781C30.7587 6.75781 29.3938 6.91593 28.082 7.2143C36.2329 9.06827 42.3168 16.359 42.3168 25.0712C42.3168 33.7834 36.2329 41.0742 28.082 42.9281C29.3938 43.2265 30.7587 43.3846 32.1607 43.3846C42.2749 43.3846 50.4741 35.1854 50.4741 25.0712C50.4741 14.957 42.2749 6.75781 32.1607 6.75781Z"
        fill="#FFD064"
      />
      <path
        d="M32.668 32.0981L38.9888 36.3527C39.2373 36.52 39.5119 36.5446 39.7523 36.4706C39.9756 36.167 40.1904 35.8566 40.3961 35.5399C40.3913 35.4795 40.3801 35.4178 40.3623 35.355L38.2691 28.0289C38.1681 27.6753 38.2913 27.2958 38.5809 27.0692L43.4081 23.2904C43.328 22.4154 43.1892 21.5573 42.996 20.72L36.4416 20.4849C36.0741 20.4718 35.7512 20.2372 35.6252 19.8918L33.0123 12.7343C32.7233 11.9427 31.6037 11.9427 31.3147 12.7343L28.7018 19.8916C28.5757 20.2369 28.253 20.4716 27.8855 20.4847L20.2711 20.7578C19.4289 20.788 19.083 21.8528 19.7465 22.3723L25.7462 27.0689C26.0356 27.2955 26.159 27.675 26.058 28.0287L23.9648 35.3548C23.7333 36.1651 24.639 36.8231 25.3382 36.3526L31.6589 32.0979C31.964 31.8928 32.363 31.8928 32.668 32.0981Z"
        fill="#E8AE4D"
      />
      <path
        d="M38.9892 36.3575C39.6883 36.8281 40.594 36.17 40.3625 35.3596L39.9831 34.0312C39.5757 34.7561 39.12 35.4501 38.6211 36.1097L38.9892 36.3575Z"
        fill="#E5A32E"
      />
      <path
        d="M44.056 20.765L41.7891 20.6836C42.0658 21.8076 42.2383 22.9723 42.2968 24.1673L44.5807 22.3794C45.2441 21.8601 44.8981 20.7952 44.056 20.765Z"
        fill="#E5A32E"
      />
    </g>
  </IconBox>
);

export default MedalBadgeIcon;
