import React, { FC } from 'react';
import styled from 'styled-components';
import PartnerLabelSvg from 'src/assets/images/partner-label.svg';
import { PartnerLogoImage } from 'src/common/components/PartnerLogoImage';
import { useAvailablePartner } from 'src/common/hooks/useAvailablePartner';

const Container = styled.div`
  align-items: baseline;
  display: flex;
  margin-bottom: 2.875rem;
  margin-top: -0.5rem;
`;

const Label = styled(PartnerLabelSvg)`
  margin-left: 0.9375rem;
  width: 3.75rem;
`;

export const PartnerLogoMobile: FC = () => {
  const { data, isLoading } = useAvailablePartner();

  if (isLoading || !data) {
    return null;
  }

  return (
    <Container>
      <PartnerLogoImage image={data.partnerLogo} />
      <Label />
    </Container>
  );
};
