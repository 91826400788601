export enum MaterialResource {
  StandardCourse = 'STANDARD_COURSE',
  ElearningLesson = 'ELEARNING_LESSON',
}

export enum MaterialType {
  Blob = 'BLOB',
  Url = 'URL',
}

export interface MaterialDTO {
  createdAt?: string;
  id: number;
  name?: string;
  resource: MaterialResource;
  type?: MaterialType;
  url?: string;
}

export interface MaterialWithAvailabilityDTO {
  available: boolean;
  material: MaterialDTO;
}

export interface Material extends MaterialDTO {
  available: MaterialWithAvailabilityDTO['available'];
}

export const mapToMaterial = (dto: MaterialWithAvailabilityDTO) => ({
  ...dto.material,
  available: dto.available,
});
