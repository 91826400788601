import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { LayoutMenuLink } from 'src/common/components/Layout/LayoutMenuLink';
import { LayoutHeaderDesktopColumn } from 'src/common/components/Layout/LayoutColumn';
import { StyleProps } from 'src/lib/styleProps';
import { UserCoursesRoute } from 'src/user/routes';
import { useIsLoggedIn } from 'src/common/hooks/useIsLoggedIn';
import { authActions } from 'src/state/auth/authActions';
import { useDispatch } from 'react-redux';
import { LoginRoute } from 'src/public/publicRoutes';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { useGatsbyLikeNavigate } from 'src/common/migration/useGatsbyLikeNavigate';
import { useRouter } from 'next/router';

const HeaderRight = styled.div`
  padding-left: 2.25rem;
`;

export const LayoutHeaderRight: FC<StyleProps> = ({ className, style }) => {
  const dispatch = useDispatch();
  const { isLoading, isLoggedIn } = useIsLoggedIn();
  const router = useRouter();
  const navigate = useGatsbyLikeNavigate();

  const redirectToLogin = useCallback(() => {
    dispatch(
      authActions.setRedirectUrl({
        pathname: router.pathname,
        publicRoute: true,
        query: router.query,
      }),
    );
    navigate(LoginRoute);
  }, [router]);

  if (isLoading) {
    return null;
  }

  return (
    <LayoutHeaderDesktopColumn className={className} style={style}>
      <HeaderRight>
        {isLoggedIn ? (
          <LayoutMenuLink kind="link" as={GatsbyLink} to={UserCoursesRoute}>
            <strong>Moje</strong> konto
          </LayoutMenuLink>
        ) : (
          <LayoutMenuLink kind="link" onClick={redirectToLogin}>
            Zaloguj się
          </LayoutMenuLink>
        )}
      </HeaderRight>
    </LayoutHeaderDesktopColumn>
  );
};
