import { StatusCodes } from 'http-status-codes';

export const youtubeChannel = 'https://www.youtube.com/channel/UCyn-y_LTqhKjaGwPWXg0WlQ';

export const courseEventsQueryInterval = 30 * 1000;
export const currentUserIncomingEventsQuery = 30 * 1000;
export const currentUserCalendarItemsRefetchInterval = 30 * 1000;
export const currentUserOngoingElearningsQuery = 30 * 1000;

export const certificateStaleTime = 30 * 60 * 1000; // 30 minutes

export const elearningNewForDaysLimit = 20;

export const permanentRedirectCode = StatusCodes.PERMANENT_REDIRECT;
export const temporaryRedirectCode = StatusCodes.TEMPORARY_REDIRECT;
