import React, { FC } from 'react';
import { useIsLoggedIn } from 'src/common/hooks/useIsLoggedIn';
import { UserPanelLoggedOut } from 'src/common/components/UserPanelLoggedOut';
import { UserPanelLoggedIn } from 'src/common/components/UserPanelLoggedIn';

export const UserPanel: FC = () => {
  const { isLoading, isLoggedIn } = useIsLoggedIn();

  if (isLoading) {
    return null;
  }

  return isLoggedIn ? <UserPanelLoggedIn /> : <UserPanelLoggedOut />;
};
