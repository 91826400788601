import React, { FC } from 'react';
import { StyleProps } from 'src/lib/styleProps';
import styled from 'styled-components';

const StyledMarkdown = styled.div`
  p {
    margin: 0;
  }
`;

export interface MarkdownProps {
  text: string;
}

/*
 * https://github.com/remarkjs/react-markdown/issues/460
 */

export const Markdown: FC<MarkdownProps & StyleProps> = ({ className, text }) => (
  <StyledMarkdown className={className} dangerouslySetInnerHTML={{ __html: text }} />
);
