import { ScrollContainer } from 'src/common/components/ScrollContainer';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { layers } from 'src/config/layers';
import OverlayScrollbars from 'overlayscrollbars';
import { colors } from 'src/config/colors';
import { StyleProps } from 'src/lib/styleProps';
import { useKeyPressEvent } from 'react-use';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 85.375rem;
`;

const ModalContent = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  background: ${colors.white};
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${layers.modal};
`;

const scrollbarOptions: OverlayScrollbars.Options = {
  overflowBehavior: { x: 'hidden' },
};

export interface ModalProps {
  footer?: ReactNode;
  header?: ReactNode;
  onClose: () => void;
}

export const Modal: FC<ModalProps & StyleProps> = ({
  children,
  className,
  footer,
  header,
  onClose,
  style,
}) => {
  const [ref, setRef] = useState<OverlayScrollbarsComponent | null>(null);

  useKeyPressEvent('Escape', onClose);

  useEffect(() => {
    const viewport = ref?.osInstance()?.getElements().viewport;

    if (viewport) {
      disableBodyScroll(viewport);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [ref]);

  return (
    <Wrapper className={className} style={style}>
      {header}

      <Container>
        <ScrollContainer options={scrollbarOptions} ref={setRef}>
          <ModalContent>{children}</ModalContent>
        </ScrollContainer>
      </Container>

      {footer}
    </Wrapper>
  );
};
