import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CalendarIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M11.727 11.6364c-1.6066 0-2.909 1.3024-2.909 2.9091v40.7272c0 1.6067 1.3024 2.9091 2.909 2.9091h40.726c1.6066 0 2.909-1.3024 2.909-2.9091V14.5455c0-1.6067-1.3024-2.9091-2.909-2.9091H11.727zM3 14.5455c0-4.81998 3.90721-8.72732 8.727-8.72732h40.726c4.8198 0 8.727 3.90734 8.727 8.72732v40.7272c0 4.82-3.9072 8.7273-8.727 8.7273H11.727C6.90721 64 3 60.0927 3 55.2727V14.5455z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M43.726 0c1.6066 0 2.909 1.30244 2.909 2.90909V14.5455c0 1.6066-1.3024 2.909-2.909 2.909-1.6066 0-2.909-1.3024-2.909-2.909V2.90909C40.817 1.30244 42.1194 0 43.726 0z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M20.454 0c1.6066 0 2.909 1.30244 2.909 2.90909V14.5455c0 1.6066-1.3024 2.909-2.909 2.909-1.6066 0-2.909-1.3024-2.909-2.909V2.90909C17.545 1.30244 18.8474 0 20.454 0z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M3 26.1818c0-1.6066 1.3024-2.9091 2.909-2.9091h52.362c1.6066 0 2.909 1.3025 2.909 2.9091 0 1.6067-1.3024 2.9091-2.909 2.9091H5.909C4.3024 29.0909 3 27.7885 3 26.1818z"
    />
  </IconBox>
);

export default CalendarIcon;
