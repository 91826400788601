import styled, { css } from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import { rem } from 'polished';
import { gutter, halfGutter } from 'src/config/layout';
import { media } from 'src/config/breakpoints';
import { layers } from 'src/config/layers';

export const LayoutBox = styled.div<{
  mobileBackground?: boolean;
  tabletBackground?: boolean;
}>`
  background: ${({ mobileBackground }) => (mobileBackground ? colors.white : 'transparent')};
  margin: 0 -${rem(halfGutter)};
  position: relative;
  z-index: ${layers.contentTop};

  ${({ tabletBackground }) =>
    media.w.min.px768(css`
      background: ${tabletBackground ? colors.white : 'transparent'};
      border-radius: 0.5rem;
      box-shadow: ${tabletBackground ? shadows.drop1 : 'none'};
      overflow: ${tabletBackground ? 'hidden' : 'visible'};
      padding: 0 ${tabletBackground ? rem(gutter) : 0};
    `)};

  ${media.w.min.px992(css`
    background: ${colors.white};
    box-shadow: ${shadows.drop1};
    margin: ${({ theme }) =>
      theme.layoutBackground ? `2.375rem -${rem(gutter)} 0` : `0 -${rem(gutter)} 4rem`};
    overflow: hidden;
    padding: 0 ${rem(gutter - 1)};
  `)};
`;
