import {
  CourseCategory,
  CourseCategoryDTO,
  CourseLevel,
  LearningPointDTO,
  TargetGroupDTO,
} from 'src/common/models/course';
import { evolve, map } from 'ramda';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { mapToSpeaker, Speaker, SpeakerDTO } from 'src/common/models/speaker';
import { fromNullable, map as mapO, toUndefined } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { EventDTO } from 'src/common/models/event';
import { KeywordDTO } from 'src/common/models/keyword';
import { mapToCategory } from 'src/common/models/category';
import { parseISODateIfExist } from 'src/lib/date';
import { CourseType } from 'src/common/models/courseType';
import { getElearningRoute } from 'src/elearning/routes';
import { StoredImageDTO } from './image';

export interface ElearningMaterial {
  id: number;
  fileName: string;
  link: string;
  locked: boolean;
}

export interface ElearningUserEnrollment {
  farthestLessonId: number | null;
  farthestPointInSeconds: number | null;
  isEnrolled: boolean;
}

export interface ElearningDTO {
  readonly id: number;
  averageRating: number | null;
  backupLimit: number;
  categories: CourseCategoryDTO[];
  comment?: string;
  description: string;
  duration: number;
  createDate: string;
  modificationTime?: string;
  elearningStatus: ElearningEventStatus;
  elearningUserEnrollment?: ElearningUserEnrollment;
  coverPhoto: StoredImageDTO;
  events: EventDTO[];
  image: StoredImageDTO;
  introduction: string;
  keywords: KeywordDTO[];
  learningPoints: LearningPointDTO[];
  level: CourseLevel;
  mainLimit: number;
  materialsQuantity?: number;
  recommendationPercent: number;
  speakers: SpeakerDTO[];
  surveyId?: number;
  targetGroups: TargetGroupDTO[];
  title: string;
  participantsCount: number;
}

export interface Elearning {
  readonly id: number;
  averageRating: number | null;
  backupLimit: number;
  categories: CourseCategory[];
  comment?: string;
  description: string;
  duration: number;
  createDate: Date;
  updateDate?: Date;
  elearningStatus: ElearningEventStatus;
  elearningUserEnrollment?: ElearningUserEnrollment;
  coverPhoto: StoredImageDTO;
  events: EventDTO[];
  image: StoredImageDTO;
  introduction: string;
  keywords: KeywordDTO[];
  learningPoints: LearningPointDTO[];
  level: CourseLevel;
  mainLimit: number;
  materialsQuantity?: number;
  recommendationPercent: number;
  speakers: Speaker[];
  surveyId?: string;
  targetGroups: TargetGroupDTO[];
  title: string;
  slug?: string;
  code?: string;
  participantsCount: number;
}

export const mapToElearning = ({
  modificationTime,
  createDate,
  ...data
}: ElearningDTO): Elearning => ({
  ...evolve({
    categories: data.categories && map(mapToCategory),
    speakers: map(mapToSpeaker),
    surveyId: (v) => v?.toString(),
  })(data),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createDate: parseISODateIfExist(createDate)!, // temporary workaround
  updateDate: parseISODateIfExist(modificationTime) ?? parseISODateIfExist(createDate),
});

export enum ElearningEventStatus {
  Initial = 'INITIAL',
  Started = 'STARTED',
  RealizedSurvey = 'REALIZED_SURVEY',
  Archived = 'ARCHIVED',
}

export const mapToCourseExcerpt = (elearning: Elearning): CourseExcerpt => ({
  id: elearning.id,
  title: elearning.title,
  slug: elearning.slug,
  courseId: elearning.id,
  image: elearning.image,
  duration: elearning.duration,
  courseType: CourseType.ElearningCourse,
  comment: elearning.comment,
  link: getElearningRoute(elearning.elearningUserEnrollment ?? null, elearning),

  elearningUserEnrollment: elearning.elearningUserEnrollment ?? null,

  speaker: pipe(
    fromNullable(elearning.speakers[0]),
    mapO((a) => ({
      id: a.id,
      firstName: a.firstName,
      lastName: a.lastName,
      companyName: a.company,
    })),
    toUndefined,
  ),
});
