import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CertificateIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M46 22c0 7.732-6.268 14-14 14s-14-6.268-14-14S24.268 8 32 8s14 6.268 14 14zm-8.5 18.1918C35.759 40.7174 33.9125 41 32 41c-2.2831 0-4.4722-.4027-6.5-1.1409v13.6054l4.2322-4.2323h3.5356L37.5 53.4645V40.1918zm5-2.3542C47.6231 34.4342 51 28.6115 51 22c0-10.4934-8.5066-19-19-19s-19 8.5066-19 19c0 6.1711 2.942 11.655 7.5 15.1258V59.5l4.2678 1.7678L31.5 54.5355l6.7322 6.7323L42.5 59.5V37.8376z"
    />
  </IconBox>
);

export default CertificateIcon;
