import React, { FC, useCallback } from 'react';
import { Portal } from 'src/common/components/Portal';
import styled, { css } from 'styled-components';
import { Modal } from 'src/common/components/Modal';
import { media } from 'src/config/breakpoints';
import CategoriesIcon from 'src/assets/icons/CategoriesIcon';
import { IconProps } from 'src/lib/IconBox';
import Certificate2Icon from 'src/assets/icons/Certificate2Icon';
import CategorySoftSkillsIcon from 'src/assets/icons/CategorySoftSkillsIcon';
import LogoutIcon from 'src/assets/icons/LogoutIcon';
import InfoIcon from 'src/assets/icons/InfoIcon';
import PlusOIcon from 'src/assets/icons/PlusOIcon';
import UserIcon from 'src/assets/icons/UserIcon';
import { colors } from 'src/config/colors';
import { CleanButton } from 'src/common/components/Button';
import ChevronIcon from 'src/assets/icons/ChevronIcon';
import { mainCategory } from 'src/common/models/category';
import { layoutActions } from 'src/state/layout/layoutActions';
import { useDispatch, useSelector } from 'react-redux';
import { layoutMobileMenuSelector } from 'src/state/layout/layoutSelectors';
import { MobileMenuState } from 'src/state/layout/layoutReducer';
import { Markdown } from 'src/common/components/Markdown';
import { AboutUsRoute, LoginRoute, LogoutRoute, ourProductsURL } from 'src/public/publicRoutes';
import { useCommonCourseCategories } from 'src/common/hooks/useCommonCourseCategories';
import { UserCoursesRoute } from 'src/user/routes';
import { coerceLinkProps } from 'src/common/utils/coerceLinkProps';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { getLinkToSearchCategory } from 'src/search/routes';
import { useIsLoggedIn } from 'src/common/hooks/useIsLoggedIn';
import { authActions } from 'src/state/auth/authActions';
import { PartnerLogoMobile } from 'src/common/components/PartnerLogoMobile';
import { useGatsbyLikeNavigate } from 'src/common/migration/useGatsbyLikeNavigate';
import { useRouter } from 'next/router';
import { SpeakersListPageRoute } from 'src/speakers/routes';

const Container = styled.div`
  position: relative;
  width: 100%;

  ${media.w.min.px576(css`
    display: flex;
  `)};
`;

const Icon = styled.svg`
  margin-right: 1.375rem;
`;

const Item = styled(CleanButton)<{ $active?: boolean }>`
  align-items: center;
  color: ${({ $active }) => ($active ? colors.red : colors.blue)};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ $active }) => ($active ? 700 : 400)};
  justify-content: flex-start;
  line-height: 1.1875rem;
  margin-bottom: 1.25rem;
  text-align: left;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const LogoutBtn = styled(Item)`
  margin-top: auto;
  margin-bottom: 3.125rem;
`;

const CategoryItem = styled(Item)`
  margin-bottom: 1.875rem;

  ${media.w.min.px576(css`
    color: ${colors.white};
  `)};
`;

const List = styled.div`
  margin-bottom: 3.125rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MainMenu = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  padding: 1rem 1.25rem 1.875rem 1.875rem;
  flex-direction: column;
  height: 100%;
  ${media.w.min.px576(css`
    display: flex;
    width: 50%;
  `)};

  ${media.w.min.px768(css`
    padding: 2.5rem 0.5rem 2.5rem 4.375rem;
  `)};
`;

const CategoriesMenu = styled(MainMenu)`
  background: ${colors.white};
  display: ${({ active }) => (active ? 'block' : 'none')};

  ${media.w.min.px576(css`
    background: ${colors.blue09};
  `)};
`;

const SubmenuIcon = styled(ChevronIcon)`
  color: ${colors.blue};
  margin-left: auto;
`;

const StyledModal = styled(Modal)`
  top: 3.375rem;

  ${media.w.min.px768(css`
    top: 4rem;
  `)};

  ${media.w.min.px992(css`
    display: none;
  `)};
`;

const ModalUnderlayer = styled.div`
  ${media.w.max.px992(css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: ${colors.white};
  `)}
`;

interface MenuItem {
  icon: FC<IconProps>;
  title: string;
  url: string;
}

const menuItems: MenuItem[][] = [
  [
    { icon: Certificate2Icon, title: 'Mój rozwój', url: UserCoursesRoute },
    {
      icon: CategorySoftSkillsIcon,
      title: 'Wykładowcy',
      url: SpeakersListPageRoute,
    },
  ],
  [
    { icon: InfoIcon, title: 'O PFP', url: AboutUsRoute },
    {
      icon: PlusOIcon,
      title: 'Poznaj produkty PFP',
      url: ourProductsURL,
    },
  ],
];

export const LayoutMobileMenuModal: FC = () => {
  const dispatch = useDispatch();
  const mobileMenuState = useSelector(layoutMobileMenuSelector);
  const { data: categories } = useCommonCourseCategories();
  const { isLoading, isLoggedIn } = useIsLoggedIn();
  const router = useRouter();
  const navigate = useGatsbyLikeNavigate();

  const hideMenu = useCallback(() => dispatch(layoutActions.hideMobileMenu()), [dispatch]);

  const redirectToLogin = useCallback(() => {
    dispatch(
      authActions.setRedirectUrl({
        pathname: router.pathname,
        publicRoute: true,
        query: router.query,
      }),
    );
    navigate(LoginRoute);
    hideMenu();
  }, [router]);

  return (
    <Portal>
      <StyledModal onClose={hideMenu}>
        <ModalUnderlayer />
        <Container>
          <MainMenu active={mobileMenuState === MobileMenuState.Default}>
            <PartnerLogoMobile />

            <Item
              $active={mobileMenuState === MobileMenuState.Categories}
              onClick={() =>
                mobileMenuState === MobileMenuState.Default
                  ? dispatch(layoutActions.showMobileCategories())
                  : dispatch(layoutActions.showMobileMenu())
              }
            >
              <Icon as={CategoriesIcon} size={18} />
              Kategorie szkoleń
              <SubmenuIcon
                direction={mobileMenuState === MobileMenuState.Default ? 'right' : 'left'}
                size={16}
              />
            </Item>

            {menuItems.map((list, index) => (
              <List key={index}>
                {list.map((item) => (
                  <React.Fragment key={item.title}>
                    <Item
                      {...coerceLinkProps(item.url ?? '#')}
                      onClick={item.url ? hideMenu : undefined}
                    >
                      <Icon as={item.icon} size={18} />
                      {item.title}
                    </Item>
                  </React.Fragment>
                ))}
              </List>
            ))}

            <List>
              {!isLoading && isLoggedIn && (
                <Item {...coerceLinkProps(UserCoursesRoute)} onClick={hideMenu}>
                  <Icon as={UserIcon} size={18} />
                  Moje konto
                </Item>
              )}

              {!isLoading && !isLoggedIn && (
                <Item onClick={redirectToLogin}>
                  <Icon as={UserIcon} size={18} />
                  Zaloguj się
                </Item>
              )}
            </List>

            {!isLoading && isLoggedIn && (
              <LogoutBtn as={GatsbyLink} to={LogoutRoute} onClick={hideMenu}>
                <Icon as={LogoutIcon} size={18} />
                Wyloguj się
              </LogoutBtn>
            )}
          </MainMenu>

          <CategoriesMenu active={mobileMenuState === MobileMenuState.Categories}>
            <List>
              <CategoryItem as={GatsbyLink} to={UserCoursesRoute} onClick={hideMenu}>
                <Icon as={mainCategory.icon} size={18} />
                {mainCategory.mobileTitle}
              </CategoryItem>

              {categories?.map((category) => (
                <CategoryItem
                  as={GatsbyLink}
                  to={getLinkToSearchCategory(category)}
                  key={category.id}
                  onClick={hideMenu}
                >
                  <Icon as={category.icon} size={18} />
                  <Markdown text={category.title} />
                </CategoryItem>
              ))}
            </List>
          </CategoriesMenu>
        </Container>
      </StyledModal>
    </Portal>
  );
};
