import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import PartnerLabelSvg from 'src/assets/images/partner-label.svg';
import { PartnerLogoImage } from 'src/common/components/PartnerLogoImage';
import { StyleProps } from 'src/lib/styleProps';
import { useAvailablePartner } from 'src/common/hooks/useAvailablePartner';

const Container = styled.div`
  border-top: 0.0625rem solid ${colors.alto};
  margin: -0.625rem 0 0.375rem 2.125rem;
  padding-top: 0.875rem;
  width: 9.1875rem;
`;

const Label = styled(PartnerLabelSvg)`
  display: block;
  margin-bottom: 0.9375rem;
  width: 3.75rem;
`;

const Logo = styled.div`
  img {
    display: block;
  }
`;

export const PartnerLogoDesktop: FC<StyleProps> = ({ className, style }) => {
  const { data, isLoading } = useAvailablePartner();

  if (isLoading || !data) {
    return null;
  }

  return (
    <Container className={className} style={style}>
      <Label />
      <Logo>
        <PartnerLogoImage image={data.partnerLogo} />
      </Logo>
    </Container>
  );
};
