import { AboutUsRoute, ourProductsURL } from 'src/public/publicRoutes';
import { SpeakersListPageRoute } from 'src/speakers/routes';

export interface MenuItem {
  label: string;
  url: string;
}

export const layoutLeftMenuItems: MenuItem[] = [
  { label: 'Wykładowcy', url: SpeakersListPageRoute },
];

export const layoutRightMenuItems: MenuItem[] = [
  { label: 'O PFP', url: AboutUsRoute },
  { label: 'Poznaj produkty PFP', url: ourProductsURL },
];
