import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { LogoHorizontal } from 'src/common/images/LogoHorizontal';
import { LogoLoginMobile } from 'src/common/images/LogoLoginMobile';
import { media } from 'src/config/breakpoints';
import classNames from 'clsx';
import Certificate2Icon from 'src/assets/icons/Certificate2Icon';
import { CleanButton } from 'src/common/components/Button';
import { typography } from 'src/config/typography';
import { layoutMobileMenuSelector } from 'src/state/layout/layoutSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from 'src/state/layout/layoutActions';
import { MobileMenuState } from 'src/state/layout/layoutReducer';
import { HomepageRoute } from 'src/public/publicRoutes';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { UserCoursesRoute } from 'src/user/routes';

const Container = styled.div<{ show: boolean }>`
  align-items: center;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: space-between;
  width: 100%;

  ${media.w.min.px576(css`
    display: flex;
  `)};
`;

const HamburgerBox = styled.span`
  display: block;
`;

const HamburgerButton = styled.button`
  &:focus {
    outline: none;
  }
`;

const ProfileButton = styled(CleanButton)`
  color: ${colors.blue};
  font-family: ${typography.fontFamilyHeaders};
  font-size: 0.625rem;
  line-height: 1rem;
  margin-left: auto;
  margin-right: 1.5625rem;

  svg {
    margin-left: 0.25rem;
  }
`;

const StyledLogoLoginMobile = styled(LogoLoginMobile)`
  display: block;

  ${media.w.min.px768(css`
    display: none;
  `)};
`;

const StyledLogoMobile = styled(LogoHorizontal)`
  display: block;

  ${media.w.max.px768(css`
    display: none;
  `)};
`;

export const LayoutMobileMenuHeaderDefault: FC = () => {
  const dispatch = useDispatch();
  const mobileMenuState = useSelector(layoutMobileMenuSelector);

  const buttonClass = useMemo(
    () =>
      classNames('hamburger', 'hamburger--slider-r', {
        'is-active': mobileMenuState !== MobileMenuState.Hidden,
      }),
    [mobileMenuState],
  );

  return (
    <Container show={mobileMenuState !== MobileMenuState.Categories}>
      <GatsbyLink to={HomepageRoute}>
        <StyledLogoLoginMobile />
        <StyledLogoMobile />
      </GatsbyLink>

      {mobileMenuState === MobileMenuState.Hidden && (
        <ProfileButton as={GatsbyLink} to={UserCoursesRoute}>
          Mój rozwój
          <Certificate2Icon size={24} />
        </ProfileButton>
      )}

      <HamburgerButton
        className={buttonClass}
        onClick={() =>
          dispatch(
            mobileMenuState === MobileMenuState.Hidden
              ? layoutActions.showMobileMenu()
              : layoutActions.hideMobileMenu(),
          )
        }
        type="button"
      >
        <HamburgerBox className="hamburger-box">
          <span className="hamburger-inner" />
        </HamburgerBox>
      </HamburgerButton>
    </Container>
  );
};
