import React, { memo } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { rem } from 'polished';
import { gutter } from 'src/config/layout';
import planYourDevelopmentSvg from 'src/assets/images/homepage-plan-your-development.url.svg';
import { RegisterRoute } from 'src/public/publicRoutes';
import { Button } from 'src/common/components/Button';
import CalendarIcon from 'src/assets/icons/CalendarIcon';
import CertificateIcon from 'src/assets/icons/CertificateIcon';
import ChartIcon from 'src/assets/icons/ChartIcon';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const Container = styled.div`
  align-self: flex-start;
  background: ${colors.grey08};
  margin-right: -${rem(gutter)};
  padding: 0.5rem 1.625rem 2.5rem;
  text-align: center;
`;

const Title = styled.h3`
  font-weight: bold;
  margin-bottom: 1.25rem;
  font-size: 1rem;
`;

const BenefitsList = styled.ul`
  padding: 0;
  text-align: left;
`;

const BenefitsListItem = styled.li`
  display: flex;
  margin: 1.1875rem 0;
  vertical-align: middle;
`;

const BenefitLabel = styled.span`
  margin-left: 0.5rem;
  vertical-align: middle;
`;

const CTAButton = styled(Button)`
  width: 100%;
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
  }
`;

export const UserPanelLoggedOut = memo(() => (
  <Container>
    <Title>Zaplanuj swój rozwój</Title>
    <ImageContainer>
      <img src={planYourDevelopmentSvg} alt="Człowiek patrzący na listę zadań" />
    </ImageContainer>
    <BenefitsList>
      <BenefitsListItem>
        <CalendarIcon size={14} />
        <BenefitLabel>Kalendarz szkoleń</BenefitLabel>
      </BenefitsListItem>
      <BenefitsListItem>
        <CertificateIcon size={14} />
        <BenefitLabel>Zdobyte certyfikaty pod ręką</BenefitLabel>
      </BenefitsListItem>
      <BenefitsListItem>
        <ChartIcon size={14} />
        <BenefitLabel>Podsumowanie postępów</BenefitLabel>
      </BenefitsListItem>
    </BenefitsList>
    <CTAButton kind="secondary" as={GatsbyLink} to={RegisterRoute}>
      Załóż konto
    </CTAButton>
  </Container>
));
UserPanelLoggedOut.displayName = 'UserPanelLoggedOut';
