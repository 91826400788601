import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { JoinWebinarButton } from 'src/course/components/JoinWebinarButton';
import { CourseEventStatus } from 'src/common/models/event';
import MedalBadgeIcon from 'src/assets/icons/MedalBadgeIcon';
import { formatCourseCalendarDate } from 'src/common/services/course';
import { isNilEmpty } from 'src/lib/isNilEmpty';
import { EventCalendarItem, mapToCourseExcerpt } from 'src/common/models/eventCalendarItem';
import { generateSurveyLink } from 'src/common/components/CourseCard/config';
import { Button } from 'src/common/components/Button';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const Course = styled.div<{ $active: boolean }>`
  position: relative;
  background: ${colors.grey08};
  border-radius: 0.25rem;
  margin-top: 0.625rem;
  padding: 0.6875rem 2.8125rem 0.6875rem 2.3125rem;

  ${({ $active }) =>
    $active &&
    css`
      background: ${colors.blue};
      color: ${colors.white};
    `}
`;

const CourseTitle = styled.div`
  font-weight: 700;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const StyledMedalBadgeIcon = styled(MedalBadgeIcon)`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
`;

const DateContainer = styled.div`
  font-weight: 300;
  line-height: 1.0625rem;
  margin-bottom: 0.5625rem;
`;

const StyledJoinWebinarButton = styled(JoinWebinarButton)`
  margin-top: 0.5625rem;
`;

const StyledButton = styled(Button)`
  margin-top: 0.5625rem;
`;

interface UserPanelOnlineCourseCalendarCardProps {
  eventCalendarItem: EventCalendarItem;
}

export const UserPanelOnlineCourseCalendarCard: FC<UserPanelOnlineCourseCalendarCardProps> = ({
  eventCalendarItem,
}) => {
  const joinWebinarButtonParams = useMemo(
    () => ({
      courseLike: {
        id: eventCalendarItem.courseId,
        title: eventCalendarItem.courseTitle,
        slug: eventCalendarItem.courseSlug,
      },
      eventLike: { id: eventCalendarItem.id },
    }),
    [eventCalendarItem],
  );

  return (
    <Course
      key={eventCalendarItem.id}
      $active={
        [CourseEventStatus.Ongoing].includes(eventCalendarItem.status) &&
        !isNilEmpty(eventCalendarItem.joinUrl)
      }
    >
      <DateContainer>
        {eventCalendarItem.status === CourseEventStatus.Realized && <>Ukończono!</>}

        {eventCalendarItem.status !== CourseEventStatus.Realized &&
          formatCourseCalendarDate(eventCalendarItem.startDateTime)}
      </DateContainer>

      <CourseTitle>{eventCalendarItem.courseTitle}</CourseTitle>

      {eventCalendarItem.joinUrl && (
        <StyledJoinWebinarButton
          courseLike={joinWebinarButtonParams.courseLike}
          eventLike={joinWebinarButtonParams.eventLike}
          webinarUrl={eventCalendarItem.joinUrl}
        />
      )}
      {eventCalendarItem.status === CourseEventStatus.Realized && (
        <StyledMedalBadgeIcon size={38} />
      )}

      {eventCalendarItem.status === CourseEventStatus.Realized && (
        <StyledButton
          as={GatsbyLink}
          kind="primary"
          size="tiny"
          to={generateSurveyLink(mapToCourseExcerpt(eventCalendarItem))}
        >
          Odbierz certyfikat
        </StyledButton>
      )}
    </Course>
  );
};
