import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const Certificate2Icon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M20.0949 22.6404c0-6.7184 5.4464-12.1647 12.1647-12.1647 6.7184 0 12.1647 5.4463 12.1647 12.1647 0 6.7183-5.4463 12.1646-12.1647 12.1646-6.7183 0-12.1647-5.4463-12.1647-12.1646zm12.1647-7.2988c-4.031 0-7.2988 3.2677-7.2988 7.2988 0 4.031 3.2678 7.2988 7.2988 7.2988 4.0311 0 7.2988-3.2678 7.2988-7.2988 0-4.0311-3.2677-7.2988-7.2988-7.2988z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M35.9974.185c1.0168-.42073 2.1895-.10651 2.8598.76628l3.4827 4.53515a2.43292 2.43292 0 001.1971.83822l5.4528 1.7216c1.0494.33132 1.7457 1.32579 1.6982 2.42525l-.2472 5.7128c-.0216.4981.1105.9909.3782 1.4116l3.0705 4.8238c.5909.9283.4851 2.1377-.2581 2.9494L49.77 29.5864a2.43194 2.43194 0 00-.6176 1.3245l-.7486 5.6689c-.1441 1.091-1.0025 1.9495-2.0935 2.0935l-2.4275.3206c.0034.0215.0066.0431.0095.0648l2.9438 22.1884c.1229.926-.2953 1.8407-1.0762 2.3535-.7808.5128-1.7863.5333-2.5873.0527l-10.913-6.5478-10.9129 6.5478c-.8011.4806-1.8067.4601-2.5875-.0528-.7809-.5129-1.199-1.4277-1.076-2.3538l2.6958-20.2969-.1771.0077c-1.0994.0476-2.0939-.6488-2.4252-1.6982l-1.7216-5.4528a2.43257 2.43257 0 00-.8382-1.1971l-4.5352-3.4827c-.87274-.6703-1.18696-1.8429-.76622-2.8598l2.18622-5.2837c.1906-.4608.2351-.969.1273-1.4558l-1.2354-5.5831c-.2378-1.0745.2753-2.1748 1.2512-2.6833l5.071-2.64229a2.433 2.433 0 001.0334-1.03336l2.6423-5.07103c.5085-.97592 1.6088-1.48899 2.6833-1.25122l5.583 1.23546a2.43315 2.43315 0 001.4559-.12737L35.9974.18501zM32.574 6.8674l3.5601-1.47301 2.3466 3.0557a7.29806 7.29806 0 003.5913 2.51462l3.674 1.16-.1666 3.8492a7.29934 7.29934 0 001.1348 4.2348l2.0688 3.2502-2.6018 2.8416a7.29833 7.29833 0 00-1.8528 3.9734l-.5044 3.8196-3.8196.5044a7.29895 7.29895 0 00-3.9735 1.8528l-2.8415 2.6018-3.2502-2.0688a7.2985 7.2985 0 00-4.2348-1.1347l-3.8492.1665-1.16-3.674a7.29898 7.29898 0 00-2.5146-3.5913l-3.0557-2.3466 1.473-3.5601a7.29905 7.29905 0 00.3821-4.3675l-.8325-3.7617 3.4168-1.7804a7.29872 7.29872 0 003.1001-3.10007l1.7803-3.41676 3.7618.83243a7.29882 7.29882 0 004.3675-.38211zM25.316 40.7362L23.1713 56.884l7.8366-4.702c.7705-.4623 1.733-.4623 2.5035 0l7.8374 4.7025-2.2129-16.6793-4.0364 3.6958c-.8116.7431-2.021.8489-2.9494.258l-4.8238-3.0705a2.43286 2.43286 0 00-1.4116-.3782l-.5987.0259z"
    />
  </IconBox>
);

export default Certificate2Icon;
