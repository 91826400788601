import { map } from 'ramda';
import { Elearning, ElearningDTO, mapToElearning } from 'src/common/models/elearning';
import {
  AuthorizedAxios,
  BaseAxios,
  MixedAxios,
  throwErrorResponse,
} from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import { PageResultDTO, unwrap as unwrapResult } from 'src/common/models/page';
import { mapToMaterial, Material, MaterialWithAvailabilityDTO } from 'src/common/models/material';
import {
  ElearningLesson,
  ElearningLessonDTO,
  ElearningLessonVideo,
  ElearningLessonVideoDTO,
  mapToElearningLessonVideo,
} from 'src/common/models/elearningLesson';
import { WatchedElearningLessonVideoChunk } from 'src/user/elearnings/models/WatchedElearningLessonVideoChunk';
import Axios from 'axios';
import { UrlDTO } from 'src/common/models/url';
import { ElearningProgress } from 'src/common/models/elearningProgress';
import { EventSearchCriteria, EventSearchCriteriaDTO } from 'src/common/models/eventSearchCriteria';
import { ListWrapper, unwrap } from 'src/lib/listWrapper';

export const joinElearning = (elearningId: number) =>
  AuthorizedAxios.post<void>(`/api/elearnings/${elearningId}/participants`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getElearningById = (id: number) =>
  MixedAxios.get<ElearningDTO>(`/api/elearnings/${id}`)
    .then(flatten)
    .then(mapToElearning)
    .catch(throwErrorResponse);

export const getElearningVideo = (id: number): Promise<UrlDTO> =>
  BaseAxios.get<UrlDTO>(`/api/elearnings/${id}/video`).then(flatten).catch(throwErrorResponse);

export const getAvailableElearnings = (
  limit: number,
  filters?: { categoryId?: number; speakerId?: number },
) =>
  MixedAxios.get<ListWrapper<ElearningDTO>>(`/api/elearnings/available`, {
    params: {
      limit,
      sortByLastChangeDate: true,
      ...filters,
    },
  })
    .then(flatten)
    .then(unwrap)
    .then(map(mapToElearning))
    .catch(throwErrorResponse);

export const getCurrentUserElearnings = (page: number, pageSize: number) => {
  return AuthorizedAxios.get<PageResultDTO<ElearningProgress>>('/api/elearnings/me', {
    params: {
      page,
      pageSize,
    },
  })
    .then(flatten)
    .then(unwrapResult);
};

export const getOngoingElearnings = (page: number, pageSize: number) => {
  return AuthorizedAxios.get<PageResultDTO<ElearningProgress>>('/api/elearnings/me/ongoing', {
    params: {
      page,
      pageSize,
    },
  })
    .then(flatten)
    .then(unwrapResult);
};

export const listElearnings = (
  criteria: EventSearchCriteria,
  pageParam: number,
  pageSize: number,
  isLoggedIn: boolean,
) => {
  const searchCriteria: EventSearchCriteriaDTO = {
    ...criteria,
    page: {
      page: pageParam,
      pageSize,
    },
  };

  return isLoggedIn
    ? MixedAxios.post<PageResultDTO<Elearning>>('/api/elearnings/criteria', searchCriteria).then(
        flatten,
      )
    : BaseAxios.post<PageResultDTO<Elearning>>(
        '/api/public/elearnings/criteria',
        searchCriteria,
      ).then(flatten);
};

export const getElearningMaterials = (elearningId: number): Promise<Material[]> =>
  AuthorizedAxios.get<ListWrapper<MaterialWithAvailabilityDTO>>(
    `/api/materials/elearning/${elearningId}`,
  )
    .then(flatten)
    .then(unwrap)
    .then(map(mapToMaterial))
    .catch(throwErrorResponse);

export const getElearningLessons = (
  elearningId: number,
  isLoggendIn: boolean,
): Promise<ElearningLesson[]> => {
  const request = isLoggendIn
    ? MixedAxios.get<ListWrapper<ElearningLessonDTO>>(`/api/lessons/course/${elearningId}`)
    : BaseAxios.get<ListWrapper<ElearningLessonDTO>>(`/api/public/lessons/course/${elearningId}`);

  return request.then(flatten).then(unwrap).catch(throwErrorResponse);
};

export const getElearningLessonVideo = (id: number): Promise<ElearningLessonVideo> =>
  AuthorizedAxios.get<ElearningLessonVideoDTO>(`/api/lessons/${id}/video`)
    .then(flatten)
    .then(mapToElearningLessonVideo)
    .catch(throwErrorResponse);

export const sendWatchedElearningLessonVideoChunk = ({
  credentials,
  lessonId,
  intervals,
}: WatchedElearningLessonVideoChunk) =>
  Axios.post(
    credentials.eventHubUrl,
    {
      token: credentials.userToken,
      intervals,
      lessonId,
    },
    {
      headers: {
        Authorization: credentials.eventHubSasToken,
      },
    },
  );
