import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const CategoriesIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0 3.20001C0 1.43269 1.43269 0 3.20001 0H25.6001c1.7673 0 3.2 1.43269 3.2 3.20001V25.6001c0 1.7673-1.4327 3.2-3.2 3.2H3.20001C1.43269 28.8001 0 27.3674 0 25.6001V3.20001zm6.40002 3.20001V22.4001H22.4001V6.40002H6.40002z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M35.1992 3.20001C35.1992 1.43269 36.6319 0 38.3992 0h22.4001c1.7673 0 3.2 1.43269 3.2 3.20001V25.6001c0 1.7673-1.4327 3.2-3.2 3.2H38.3992c-1.7673 0-3.2-1.4327-3.2-3.2V3.20001zm6.4 3.20001V22.4001h16.0001V6.40002H41.5992z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M35.1992 38.3992c0-1.7673 1.4327-3.2 3.2-3.2h22.4001c1.7673 0 3.2 1.4327 3.2 3.2v22.4001c0 1.7673-1.4327 3.2-3.2 3.2H38.3992c-1.7673 0-3.2-1.4327-3.2-3.2V38.3992zm6.4 3.2v16.0001h16.0001V41.5992H41.5992z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0 38.3992c0-1.7673 1.43269-3.2 3.20001-3.2H25.6001c1.7673 0 3.2 1.4327 3.2 3.2v22.4001c0 1.7673-1.4327 3.2-3.2 3.2H3.20001C1.43269 63.9993 0 62.5666 0 60.7993V38.3992zm6.40002 3.2v16.0001H22.4001V41.5992H6.40002z"
    />
  </IconBox>
);

export default CategoriesIcon;
