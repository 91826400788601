import React, { FC } from 'react';
import { Button } from 'src/common/components/Button';
import styled from 'styled-components';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { generateDetailsLink } from 'src/common/components/CourseCard/config';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const StyledButton = styled(Button)`
  margin-top: 0.5625rem;
`;

export interface ResumeElearningButtonProps {
  courseExcerpt: CourseExcerpt;
}

export const ResumeElearningButton: FC<ResumeElearningButtonProps> = ({ courseExcerpt }) => (
  <StyledButton as={GatsbyLink} to={generateDetailsLink(courseExcerpt)} kind="border" size="tiny">
    Wznów
  </StyledButton>
);
