import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import CalendarIcon from 'src/assets/icons/CalendarIcon';
import { rem } from 'polished';
import { gutter } from 'src/config/layout';
import { CurrentUserInfo } from 'src/common/components/CurrentUserInfo';
import { SpeakerCardSkeleton } from 'src/common/components/SpeakerCardSkeleton';
import { UserPanelElearningCalendarCard } from 'src/common/components/UserPanelLoggedIn/UserPanelElearningCalendarCard';
import { UserPanelOnlineCourseCalendarCard } from 'src/common/components/UserPanelLoggedIn/UserPanelOnlineCourseCalendarCard';
import ChevronIcon from 'src/assets/icons/ChevronIcon';
import { CleanButton } from 'src/common/components/Button';
import { useCurrentUserElearningsQuery } from 'src/common/hooks/elearning/useCurrentUserElearningsQuery';
import { ElearningProgress } from 'src/common/models/elearningProgress';
import { useCurrentUserCalendarEventsQuery } from 'src/common/hooks/event/useCurrentUserCalendarEventsQuery';
import { EventCalendarItem } from 'src/common/models/eventCalendarItem';

const Container = styled.div`
  flex: 1 1 auto;
  margin-right: -${rem(gutter)};
  max-width: 15.25rem;
`;

const Information = styled.div`
  padding: 0.6875rem 2.8125rem 0.6875rem 2.3125rem;
`;

const ShowMoreButton = styled(CleanButton)`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin: 0.625rem 0 0 3.125rem;
`;

const Section = styled.div`
  padding-bottom: 1.875rem;
  padding-top: 4.0625rem;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-right: 0.5625rem;
`;

const StyledCurrentUserInfo = styled(CurrentUserInfo)`
  padding: 2.375rem 0 0 1.5rem;
`;

const Title = styled.div`
  padding: 0 0 0.375rem 2.5rem;
`;

export const UserPanelLoggedIn = memo(() => {
  const [elearningsToShow, setElearningsToShow] = useState<ElearningProgress[] | undefined>();
  const [onlineCoursesToShow, setOnlineCoursesToShow] = useState<EventCalendarItem[] | undefined>();
  const [showAll, setShowAll] = useState(false);
  const [showMoreButtonAvailable, setShowMoreButtonAvailable] = useState(false);

  const {
    data: events,
    isError: eventsError,
    isLoading: eventsLoading,
    isSuccess: eventsSuccess,
  } = useCurrentUserCalendarEventsQuery();

  const {
    data: elearnings,
    isError: elearningsError,
    isLoading: elearningsLoading,
    isSuccess: elearningsSuccess,
  } = useCurrentUserElearningsQuery();

  useEffect(() => {
    if (showAll) {
      setOnlineCoursesToShow(events);
      setElearningsToShow(elearnings);
    } else {
      events && setOnlineCoursesToShow(events.slice(0, 2));
      elearnings && setElearningsToShow(elearnings.slice(0, 2));
    }
  }, [elearnings, events, showAll]);

  useEffect(() => {
    if ((events?.length ?? 0) > 2 || (elearnings?.length ?? 0) > 2) {
      setShowMoreButtonAvailable(true);
    }
  }, [elearnings, events]);

  const isEmpty =
    elearningsSuccess && elearnings?.length === 0 && eventsSuccess && events?.length === 0;
  const isError = elearningsError || eventsError;
  const isLoading = elearningsLoading || eventsLoading;

  return (
    <Container>
      <StyledCurrentUserInfo />

      <Section>
        <Title>
          <StyledCalendarIcon size={21} />
          Kalendarz szkoleń
        </Title>

        {isError && <Information>Wystąpił błąd podczas ładowania szkoleń online.</Information>}

        {isEmpty && <Information>Nie masz żadnych nadchodzących szkoleń online.</Information>}

        {isLoading && <SpeakerCardSkeleton />}

        {onlineCoursesToShow && (
          <div>
            {onlineCoursesToShow.map((a) => (
              <UserPanelOnlineCourseCalendarCard key={a.courseId} eventCalendarItem={a} />
            ))}
          </div>
        )}

        {elearningsToShow && (
          <div>
            {elearningsToShow.map((a) => (
              <UserPanelElearningCalendarCard key={a.id} elearningProgress={a} />
            ))}
          </div>
        )}

        {showMoreButtonAvailable && (
          <ShowMoreButton onClick={() => setShowAll(!showAll)}>
            <ChevronIcon size={13} direction={showAll ? 'up' : 'down'} />
            <span>{!showAll ? 'Zobacz więcej' : 'Zwiń listę'}</span>
          </ShowMoreButton>
        )}
      </Section>
    </Container>
  );
});
UserPanelLoggedIn.displayName = 'UserPanelLoggedIn';
